import { graphql } from 'gatsby';
import Link from '@components/Link';

import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Avatar from '@components/Avatar/Avatar';
import CardAction from '@components/CardAction/CardAction';
import CardGrid from '@components/CardGrid/CardGrid';
import ResourcesLink from '@components/ResourcesLink';
// Modules
import Footer from '@components/Footer/Footer';
import GenericArticle from '@components/GenericArticle/GenericArticle';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import SocialSharing from '@components/SocialSharing/SocialSharing';
import './ArticleTemplate.scss';
import { UserLang } from '@constants/common';
import { getOriginalLocale } from '@helpers/GlobalHelpers';
import { useLocale } from '@hooks';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        aepsyCms {
            articles(locale: "all", pagination: { limit: 100 }) {
                data {
                    attributes {
                        title
                        description
                        slug
                        locale
                        content
                        tagline
                        coverPicture {
                            data {
                                attributes {
                                    url
                                    urlSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 300, quality: 80) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ArticleTemplate = ({ pageContext: { edge }, data: graphData }) => {
    const { currentLanguage } = useLocale();
    const [articlesList, setArticlesList] = useState(null);

    useEffect(() => {
        if (graphData.aepsyCms.articles) {
            setArticlesList(
                graphData.aepsyCms.articles.data
                    .filter(
                        (item) =>
                            getOriginalLocale(item.attributes.locale) ===
                            currentLanguage
                    )
                    .map((item) => item.attributes)
            );
        }
    }, [graphData, currentLanguage]);

    const { title, content, description } = edge;

    function renderFooterAvatar() {
        return (
            <div className="ArticleTemplate--FooterAvatar">
                <Avatar
                    size="m"
                    align="center"
                    src={'/img/logo/logo_square_dark.png'}
                />
            </div>
        );
    }

    function renderAuthorCta() {
        return (
            <Link to="/about/">
                <Button
                    variant="primary"
                    align="center"
                    size="l"
                    label="Erfahre mehr über unsere Mission"
                />
            </Link>
        );
    }

    return (
        <Layout>
            <div className="ArticleTemplate">
                <Seo title={title + ' | Aepsy'} description={description} />
                <div className="global_theme-green">
                    {/* <TopBar /> */}
                    <HeaderNav />

                    {/* Header */}
                    <div className="ArticleTemplate--Header">
                        <Section
                            container="large"
                            spacingTop="xl"
                            spacingBottom="l"
                        >
                            <Text align="center">
                                {/* <Link to="/ressourcen/"><Button variant="inline" label="Lesen"/></Link> / {lowerCase(title)} */}
                                <ResourcesLink>
                                    <Button variant="inline" label={'Zurück'} />
                                </ResourcesLink>
                            </Text>
                            <Title
                                tag="h1"
                                align="center"
                                font="alt"
                                size="giant"
                            >
                                {title}
                            </Title>
                            <div className="g_center">
                                <SocialSharing
                                    title={'Wissenswert'}
                                    summary={description}
                                    theme="dark"
                                    size="s"
                                />
                            </div>
                        </Section>
                    </div>

                    {/* Content */}
                    <Section
                        container="large"
                        spacingTop="m"
                        spacingBottom="m"
                        theme="white"
                    >
                        <GenericArticle>
                            {
                                <div
                                    className="MarkdownText"
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                ></div>
                            }
                        </GenericArticle>

                        {/* share */}
                        <Divider spacing="xl" invisible />
                        <div className="g_clearfix">
                            <div className="g_2_3 g_center">
                                <Divider spacing="l" />
                                <SocialSharing
                                    title={'Wissenswert'}
                                    summary={description}
                                    theme="dark"
                                />
                                <Divider spacing="l" />
                            </div>
                        </div>
                    </Section>

                    {/* Author */}
                    <Section container="large" spacingBottom="xl" theme="white">
                        <div className="ArticleTemplate--Author g_1_2 g_center">
                            {/* Avatar */}
                            {renderFooterAvatar()}

                            <Divider invisible spacing="xxs" />
                            {/* Description */}
                            <Text align="center">
                                Aepsy menschliche Kreativität, Ästhetik und
                                Technologie für einfachen, stigmafreien Zugang
                                zu mentaler Gesundheit. Wir reissen Hürden ein
                                und setzten Köpfe frei. Bis alle mental befreit
                                ihren Träumen folgen können. Für uns, für dich
                                und die Generationen nach uns.
                            </Text>

                            <Divider invisible spacing="m" />
                            {/* CTA */}
                            {renderAuthorCta()}
                        </div>
                    </Section>

                    {/* More articles */}
                    <div id="mehr">
                        {/* Introduction */}
                        <Section spacing="l">
                            <Section container="short">
                                <Title align="center">Weitere Artikel</Title>
                            </Section>

                            {/* Cards */}
                            <Section container="large" spacingTop="m">
                                <CardGrid>
                                    {articlesList
                                        ? articlesList.map((_el, index) => {
                                              if (title !== _el.title) {
                                                  return (
                                                      <CardAction
                                                          key={index}
                                                          variant="image"
                                                          ctaLink={`${
                                                              currentLanguage ===
                                                              UserLang.German
                                                                  ? '/ressourcen/'
                                                                  : '/resources/'
                                                          }${_el.slug}`}
                                                          image={
                                                              _el.coverPicture
                                                                  .data
                                                                  .attributes
                                                                  .url
                                                          }
                                                          imageSharp={
                                                            _el.coverPicture.data.attributes.urlSharp
                                                                ? _el.coverPicture
                                                                    .data
                                                                    .attributes
                                                                    .urlSharp
                                                                : ''
                                                        }
                                                      >
                                                          <Title
                                                              tag="h4"
                                                              size="l"
                                                              font="alt"
                                                          >
                                                              {_el.title}
                                                          </Title>
                                                          <Text size="s">
                                                              {_el.tagline}
                                                          </Text>
                                                      </CardAction>
                                                  );
                                              } else {
                                                  return '';
                                              }
                                          })
                                        : ''}
                                </CardGrid>
                            </Section>
                        </Section>
                    </div>

                    <Footer />
                </div>
            </div>
        </Layout>
    );
};

export default withLocale(ArticleTemplate);
